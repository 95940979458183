import { cilPlus } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CButton,
  CCol,
  CForm,
  CFormInput,
} from '@coreui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import Select from 'react-select'
import 'simplebar/dist/simplebar.min.css'
import { validateGameForm, validationMap } from 'src/components/forms/formUtils'
import { GAME_TYPES, LOCALES } from 'src/variables'
import { v4 } from 'uuid'
import { object } from 'yup'
import { GameFields } from '../gameFields'
import { TranslationForm } from '../translationForm'

export const GameFormCreate = ({ initialValue, onSubmit, onChange }) => {
  // const userToken = useSelector((state) => state.user.token)

  const formId = v4()

  const [configurations, setConfigurations] = useState(initialValue?.locales ?? [])
  const [type, setType] = useState(
    initialValue?.type
      ? GAME_TYPES.find((el) => el.value === initialValue.type).value
      : GAME_TYPES[0].value,
  )
  const [alertMsg, setAlertMsg] = useState('')

  const resolverSchema = object({
    id: validationMap.number.required(),
    order: validationMap.number.required(),
  }).required()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resolverSchema),
    defaultValues: {
      id: initialValue?.id ?? -1,
      order: initialValue?.order ?? -1,
    },
  })

  const watchFormFields = useWatch({ control })

  const configurationItems = [
    {
      name: 'title',
      label: 'Titolo gioco',
      type: 'string',
    },
    {
      name: 'description',
      label: 'Descrizione gioco',
      type: 'string',
    },
    {
      name: 'hint',
      label: 'Suggerimento gioco',
      type: 'string',
    },
    ...GameFields[type],
  ]

  useEffect(() => {
    onChange({
      ...watchFormFields,
      locales: configurations,
      type,
    })
  }, [watchFormFields, configurations, type])

  useEffect(() => {
    if (alertMsg) alert(alertMsg)
  }, [alertMsg])

  // // map a configuration transforming every game related field into a single config string
  // function configuration2Record(configuration) {
  //   const { id, title, description, hint, locale, isDefault, ...config } = configuration

  //   console.log('config: ', config)
  //   return {
  //     id,
  //     title,
  //     description,
  //     hint,
  //     config: JSON.stringify(
  //       GameFields[type].reduce(
  //         (acc, field) => ({
  //           ...acc,
  //           [field.name]: field.name === 'reward' ? Number(config[field.name]) : config[field.name],
  //         }),
  //         {},
  //       ),
  //     ),
  //     locale,
  //     isDefault,
  //   }
  // }

  function onConfigurationFormChange(index, data) {
    // console.log('GAME - configuration form change', index, data)
    const newConfigurations = [...configurations]
    newConfigurations[index] = data
    setConfigurations(newConfigurations)
  }

  function addConfiguration() {
    if (configurations.length < LOCALES.length) {
      // console.log('GAME - adding new configuration')
      setConfigurations([...configurations, {}])
    }
  }

  function submitGameForm(v) {
    const validationRes = validateGameForm({
      ...v,
      type,
      locales: configurations,
    })

    if (!validationRes) {
      onSubmit({
        ...v,
        type,
        locales: configurations,
      })
    } else {
      setAlertMsg(validationRes)
    }
  }

  const configurationsAccordionItems = configurations.map((configuration, index) => (
    <CAccordionItem key={index}>
      <CAccordionHeader>
        {configuration?.locale
          ? `${index} - ${configuration?.locale}`
          : `Configurazione n.${index}`}
      </CAccordionHeader>
      <CAccordionBody>
        <TranslationForm
          locales={LOCALES}
          defaultLocale={LOCALES[index]}
          initialValue={configuration}
          items={configurationItems}
          onChange={(data) => onConfigurationFormChange(index, data)}
        ></TranslationForm>
      </CAccordionBody>
    </CAccordionItem>
  ))

  return (
    <>
      <CForm id={formId} onSubmit={handleSubmit(submitGameForm)}>
        <Select
          options={GAME_TYPES}
          defaultValue={GAME_TYPES.find((el) => el.value === type)}
          onChange={(v) => setType(v.value)}
        />
        <Controller
          render={({ field }) => {
            return (
              <>
                <CFormInput
                  defaultValue={field.value}
                  type="number"
                  label="Numero gioco"
                  onChange={field.onChange}
                />
                <CCol xs={12}>
                  <span>{errors[field.name]?.message}</span>
                </CCol>
              </>
            )
          }}
          name="order"
          control={control}
        />
      </CForm>
      <h4>Configurazioni gioco</h4>
      <CButton className="form-add-subitem-button" onClick={addConfiguration}>
        <CIcon icon={cilPlus} /> Aggiungi configurazione
      </CButton>
      <CAccordion>{configurationsAccordionItems}</CAccordion>
      {onSubmit && (
        <CButton form={formId} type="submit">
          Salva
        </CButton>
      )}
    </>
  )
}

GameFormCreate.propTypes = {
  initialValue: PropTypes.object,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func.isRequired,
}
