import axios from 'axios'

function makeHeaders({ bearer, additionalHeaders }) {
  let headers = {
    headers: {},
  }

  if (bearer) headers.headers['Authorization'] = 'Bearer ' + bearer

  if (additionalHeaders) {
    headers.headers = { ...headers.headers, ...additionalHeaders }
  }

  return headers
}

function makeBearerHeaders(bearer) {
  return {
    headers: {
      Authorization: 'Bearer ' + bearer,
    },
  }
}

export async function post({ endpoint, bearerToken, data, useFormData }) {
  const headers = makeHeaders({
    bearer: bearerToken,
    additionalHeaders: useFormData ? { 'Content-Type': 'multipart/form-data' } : null,
  })
  try {
    const res = await axios.post(endpoint, data, headers)
    return res.data
  } catch (err) {
    throw err
  }
}
export async function postUnwrapped({ endpoint, bearerToken, data, useFormData }) {
  const headers = makeHeaders({
    bearer: bearerToken,
    additionalHeaders: useFormData ? { 'Content-Type': 'multipart/form-data' } : null,
  })
  try {
    const res = await axios.post(endpoint, data, headers)
    return res
  } catch (err) {
    throw err
  }
}

export async function patch({ endpoint, bearerToken, data }) {
  const headers = !!bearerToken ? makeBearerHeaders(bearerToken) : undefined
  try {
    const res = await axios.patch(endpoint, data, headers)
    return res.data
  } catch (err) {
    throw err
  }
}

export async function get({ endpoint, bearerToken }) {
  const headers = !!bearerToken ? makeBearerHeaders(bearerToken) : undefined
  try {
    const res = await axios.get(endpoint, headers)
    return res.data
  } catch (err) {
    throw err
  }
}

export async function del({ endpoint, bearerToken, data }) {
  let headers = !!bearerToken ? makeBearerHeaders(bearerToken) : undefined
  if (data) {
    if (!headers) headers = {}
    headers = { ...headers, data }
  }
  try {
    const res = await axios.delete(endpoint, headers)
    return res.data
  } catch (err) {
    throw err
  }
}
