import { configureStore } from '@reduxjs/toolkit'
import sidebarReducer from './slices/sidebarSlice'
import userReducer from './slices/userSlice'

// const initialState = {
//   sidebarShow: true,
// }

// const changeState = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case 'set':
//       return { ...state, ...rest }
//     default:
//       return state
//   }
// }

const store = configureStore({
  reducer: {
    user: userReducer,
    sidebar: sidebarReducer,
  },
})
// const store = configureStore(changeState)
export default store
