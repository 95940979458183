export const GameFields = {
  singleChoiceQuiz: [
    {
      name: 'question',
      label: 'Domanda',
      type: 'string',
    },
    {
      name: 'answer',
      label: 'Risposta',
      type: 'string',
    },
    {
      name: 'reward',
      label: 'Ricompensa',
      type: 'number',
    },
  ],
  multipleChoiceQuiz: [
    {
      name: 'question',
      label: 'Domanda',
      type: 'string',
    },
    {
      name: 'answers',
      label: 'Risposte',
      type: 'array',
      nestedItems: [
        {
          name: 'answer',
          type: 'string',
        },
        {
          name: 'isCorrect',
          label: 'Corretta',
          type: 'boolean',
        },
      ],
    },
    {
      name: 'reward',
      label: 'Ricompensa',
      type: 'number',
    },
  ],
  drawingsAndWords: [
    {
      name: 'question',
      label: 'Domanda',
      type: 'string',
    },
    {
      name: 'answers',
      label: 'Risposte',
      type: 'array',
      nestedItems: [
        {
          name: 'answer',
          type: 'string',
        },
        {
          name: 'isCorrect',
          label: 'Corretta',
          type: 'boolean',
        },
      ],
    },
    {
      name: 'reward',
      label: 'Ricompensa',
      type: 'number',
    },
  ],
  riddle: [
    {
      name: 'question',
      label: 'Domanda',
      type: 'string',
    },
    {
      name: 'answers',
      label: 'Risposte',
      type: 'array',
      nestedItems: [
        {
          name: 'answer',
          type: 'string',
        },
        {
          name: 'isCorrect',
          label: 'Corretta',
          type: 'boolean',
        },
      ],
    },
    {
      name: 'reward',
      label: 'Ricompensa',
      type: 'number',
    },
  ],
  timedQuiz: [
    {
      name: 'question',
      label: 'Domanda',
      type: 'string',
    },
    {
      name: 'answer',
      label: 'Risposta',
      type: 'string',
    },
    {
      name: 'timeLimit',
      label: 'Limite di tempo',
      type: 'number',
    },
    {
      name: 'reward',
      label: 'Ricompensa',
      type: 'number',
    },
  ],
  rebus: [
    {
      name: 'imageUrl',
      label: 'Url immagine',
      type: 'string',
    },
    {
      name: 'answer',
      label: 'Risposta',
      type: 'string',
    },
    {
      name: 'reward',
      label: 'Ricompensa',
      type: 'number',
    },
  ],
  photoQuiz: [
    {
      name: 'imageUrl',
      label: 'Url immagine',
      type: 'string',
    },
    {
      name: 'answer',
      label: 'Risposta',
      type: 'string',
    },
    {
      name: 'reward',
      label: 'Ricompensa',
      type: 'number',
    },
  ],
  hangman: [
    {
      name: 'question',
      label: 'Domanda',
      type: 'string',
    },
    {
      name: 'answer',
      label: 'Risposta',
      type: 'string',
    },
    {
      name: 'reward',
      label: 'Ricompensa',
      type: 'number',
    },
  ],
  reorderItems: [
    {
      name: 'question',
      label: 'Domanda',
      type: 'string',
    },
    {
      name: 'answers',
      label: 'Risposte',
      type: 'array',
      nestedItems: [
        {
          name: 'answer',
          label: 'Risposta',
          type: 'string',
        },
        {
          name: 'order',
          label: 'Ordine',
          type: 'number',
        },
      ],
    },
    {
      name: 'reward',
      label: 'Ricompensa',
      type: 'number',
    },
  ],
  reorderLetters: [
    {
      name: 'question',
      label: 'Domanda',
      type: 'string',
    },
    {
      name: 'answer',
      label: 'Risposta',
      type: 'string',
    },
    {
      name: 'reward',
      label: 'Ricompensa',
      type: 'number',
    },
  ],
}
