import { cilTrash } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react'
import PropTypes from 'prop-types'
import React from 'react'

export const MyCard = ({ heading, children, onClick, headerButton }) => {
  return (
    <CCard
      className="my-card"
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}
    >
      <CCardHeader>
        <CRow>
          <CCol sm={9}>{heading}</CCol>
          <CCol sm={3} className="m-0">
            {headerButton !== undefined && (
              <CButton
                color="danger"
                variant="outline"
                className="accordion-remove-button"
                onClick={headerButton.onClick}
              >
                <CIcon icon={headerButton.icon} />
              </CButton>
            )}
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>{children}</CCardBody>
    </CCard>
  )
}

MyCard.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  headerButton: PropTypes.object,
}
