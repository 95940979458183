import { cilPlus, cilTrash } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
} from '@coreui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import 'simplebar/dist/simplebar.min.css'
import { LOCALES } from 'src/variables'
import { v4 } from 'uuid'
import { object } from 'yup'
import { validationMap } from './formUtils'
import { TranslationForm } from './translationForm'

export const TagForm = ({ initialValue, onSubmit, onChange }) => {
  const formId = v4()

  const [translations, setTranslations] = useState(initialValue?.locales ?? [])
  const [alertMsg, setAlertMsg] = useState('')

  const resolverSchema = object({
    id: validationMap.number.required(),
    order: validationMap.number.required(),
    logoName: validationMap.string.required(),
  }).required()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resolverSchema),
    defaultValues: {
      id: initialValue?.id ?? -1,
      order: initialValue?.order ?? 1,
      logoName: initialValue?.logoName ?? '',
    },
  })

  const watchFormFields = useWatch({ control })

  const translationItems = [
    {
      name: 'name',
      label: 'Titolo tag',
      type: 'string',
    },
    {
      name: 'description',
      label: 'Descrizione tag',
      type: 'string',
    },
  ]

  useEffect(() => {
    onChange({
      ...watchFormFields,
      locales: translations,
    })
  }, [watchFormFields, translations])

  useEffect(() => {
    if (alertMsg) alert(alertMsg)
  }, [alertMsg])

  function onTranslationFormChange(index, data) {
    const newTranslations = [...translations]
    newTranslations[index] = data
    setTranslations(newTranslations)
  }

  function removeTranslation(index) {
    let newTranslations = [...translations]
    newTranslations.splice(index, 1)
    setTranslations(newTranslations)
  }

  function addTranslation() {
    setTranslations([...translations, {}])
  }

  function validateTagForm() {
    if (translations.length === 0) {
      return 'Form non valido. Non ci sono traduzioni'
    }

    let thereIsDefaultTranslation = false

    let i
    for (i = 0; i < translations.length; i++) {
      const locale = translations[i]

      // check if there are multiple default locales
      if (locale.isDefault) {
        if (thereIsDefaultTranslation) {
          return 'Form non valido. Ci sono più traduzioni di default'
        } else thereIsDefaultTranslation = true
      }

      const keys = Object.keys(locale)
      // check if there is at least one property
      // if (keys.length === 0) {
      //   return false
      // }

      // check if all fields are filled
      for (let i = 0; i < keys.length; i++) {
        if (locale[keys[i]] === undefined) {
          return 'Form non valido. Uno o piú campi non sono stati compilati'
        }
      }
    }

    // check if there is at least one default locale
    if (!thereIsDefaultTranslation) {
      return "Form non valido. Non c'é una traduzione di default"
    }

    return null
  }

  function submitTagForm(v) {
    const validationRes = validateTagForm()

    if (!validationRes) {
      onSubmit({
        ...v,
        locales: translations,
      })
    } else setAlertMsg(validationRes)
  }

  const translationsAccordionItems = translations.map((translation, index) => (
    <CAccordionItem key={index}>
      <CAccordionHeader>
        {translation?.locale ? `${index} - ${translation?.locale}` : `Traduzione n.${index}`}
      </CAccordionHeader>
      <CAccordionBody>
        <CButton
          color="danger"
          variant="outline"
          className="accordion-remove-button"
          onClick={() => removeTranslation(index)}
        >
          <CIcon icon={cilTrash} />
        </CButton>
        <TranslationForm
          locales={LOCALES}
          defaultLocale={LOCALES[index]}
          items={translationItems}
          initialValue={translation}
          onChange={(data) => onTranslationFormChange(index, data)}
        ></TranslationForm>
      </CAccordionBody>
    </CAccordionItem>
  ))

  return (
    <>
      <CForm id={formId} onSubmit={handleSubmit(submitTagForm)}>
        <Controller
          render={({ field }) => {
            return (
              <>
                <CFormLabel htmlFor="order-field">Ordine</CFormLabel>
                <CFormInput
                  defaultValue={field.value}
                  id="order-field"
                  type="number"
                  min={1}
                  onChange={field.onChange}
                />
                <CCol xs={12}>
                  <span>{errors[field.name]?.message}</span>
                </CCol>
              </>
            )
          }}
          name="order"
          control={control}
        />
        <Controller
          render={({ field }) => {
            return (
              <>
                <CFormLabel htmlFor="logoName-field">Nome Immagine</CFormLabel>
                <CFormInput
                  defaultValue={field.value}
                  id="logoName-field"
                  type="string"
                  onInput={field.onChange}
                />
                <CCol xs={12}>
                  <span>{errors[field.name]?.message}</span>
                </CCol>
              </>
            )
          }}
          name="logoName"
          control={control}
        />
      </CForm>
      <h4>Traduzioni tag</h4>
      <CButton onClick={addTranslation} className="form-add-subitem-button">
        <CIcon icon={cilPlus} /> Aggiungi traduzione
      </CButton>
      <CAccordion>{translationsAccordionItems}</CAccordion>
      {onSubmit && (
        <CButton className="itinerary-form-submit-button" form={formId} type="submit">
          Salva
        </CButton>
      )}
    </>
  )
}

TagForm.propTypes = {
  initialValue: PropTypes.object,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func.isRequired,
}
